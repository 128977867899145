import React from 'react'
import './FaqsEs.css';
import bg from './cta-faqs-v2.jpg';
import constants from '../../constants.json';
const faqs = [
    {
        title: '¿Quiénes Somos?',
        content: '<p>VPixel CO es un estudio independiente y boutique de diseño y desarrollo web dirigido por dos ingenieros de diseño de clase mundial con más de 10 años de experiencia combinada. Atendemos a clientes en todo el mundo, incluyendo:</p><ul><li>Startups</li><li>Empresas en etapa temprana</li><li>Grandes corporaciones</li><li>ONGs</li></ul><p>Para estos clientes, diseñamos y desarrollamos productos digitales como landing pages, sitios web WordPress y tiendas en línea. Nuestro equipo está compuesto por:</p><ul><li>4 diseñadores</li><li>5 desarrolladores web</li><li>2 especialistas en SEO/SEM</li></ul><p>que combinan su experiencia para crear productos digitales excepcionales.</p>'
    },
    {
        title: '¿Cuánto cobramos por un sitio web?',
        content: '<p></p><p>Nuestros servicios de Diseño y Desarrollo Web generalmente oscilan entre $230 y $1200 USD. Sin embargo, el costo final depende de las necesidades y requisitos específicos de cada proyecto. Aquí hay una estimación aproximada basada en el tipo de producto:</p><ul><li>Landing Pages (página informativa única): desde $230 USD (Por tiempo limitado estamos ofertándolas desde $190 USD)</li><li>Sitios web corporativos gestionables: desde $600 USD</li><li>Tiendas en línea completas: desde $900 USD</li></ul><p>Contáctenos hoy, conversemos y podemos proporcionarle una cotización adaptada a las necesidades de su proyecto.</p>'
    },
    {
        title: '¿Por qué cobramos esos precios?',
        content: '<p>Crear un sitio web implica un proceso que requiere tiempo, planificación e investigación exhaustiva. A diferencia de otras agencias, <strong class="highlight">en VPixel CO NO utilizamos plantillas prefabricadas</strong>.</p><p>Así mismo, contamos con un <strong>equipo</strong> de profesionales en áreas como Diseño de UI/UX, Desarrollo Web y QA que trabaja en conjunto para cada proyecto asegurando así un diseño premium, único y 100% diseñado, personalizado y adaptado para atender las necesidades y requerimientos de su negocio.</p><p>Adquirir un sitio web con nosotros no debe verse solo como un gasto empresarial; creemos que un sitio web es una herramienta de ventas especialmente diseñada para generar ingresos para el cliente.</p>'
    },
    {
        title: '¿Ofrecen un plan de pago?',
        content: '<p>Sí, dividimos el pago en dos partes:</p><ol><li>El primer 50% se realiza típicamente una vez que el cliente está listo para proceder.</li><li>El 50% restante se debe realizar 20 días calendario después del primer pago o al entregar el proyecto, lo que ocurra primero.</li></ol><p>Lea más sobre esto en nuestros <a href="https://vpixel.co/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>.</p><p>Si necesita una solución de pago diferente, por favor comuníquese con VPixel CO – Estamos abiertos a manejar propuestas flexibles</p>'
    },
    {
        title: '¿Ofrecen descuento para ONGs?',
        content: '<p>Sí, ofrecemos un descuento del 20% para organizaciones sin fines de lucro. Por favor, contáctenos para discutir su proyecto y cómo podemos ayudarle a alcanzar sus objetivos.</p>'
    },
    {
        title: '¿Cuánto tardar en desarrollar un sitio web?',
        content: '<p>Dependiendo del proyecto, un sitio web puede tardar entre 2 y 6 semanas en completarse. Aquí hay un desglose:</p><ul><li>Landing pages: aproximadamente 2 semanas (Por tiempo limitado podemos hacer entregas desde 7 días calendario en adelante)</li><li>Sitios web de marketing y corporativos: entre 2 y 4 semanas</li><li>Tiendas de comercio electrónico: entre 2 y 6 semanas</li></ul><p>Proporcionaremos un cronograma preciso una vez que tengamos los detalles del proyecto.</p>'
    },
    {
        title: '¿Qué necesitan para iniciar un proyecto?',
        content: '<p>Los requisitos varían dependiendo del tipo de sitio web que el cliente esté buscando. Se llevará a cabo una discusión detallada durante la llamada de descubrimiento antes de iniciar la colaboración. Dependiendo del paquete acordado, podrían ser necesarios los siguientes elementos:</p><ul><li>Fotos del equipo del cliente</li><li>Imágenes del lugar de trabajo del cliente</li><li>Contenido del sitio web como texto, PDFs, etc.</li><li>Perfiles del personal del cliente</li><li>Cualquier video que el cliente quiera incluir en el sitio web</li><li>Detalles de inicio de sesión del sitio web actual del cliente</li></ul><p>Parte de esta información podría estar ya disponible en el sitio web actual del cliente, en cuyo caso, se puede transferir fácilmente al nuevo sitio.</p>'
    },
    {
        title: '¿Qué pasa si no me gusta el diseño?',
        content: '<p>Ofrecemos una <span style="font-weight: bold; text-transform: uppercase;" class="color-yellow">garantía sin riesgo</span>. Nuestro proceso incluye:</p><ol><li>Diseñar un mockup de su página de inicio antes de construir su nuevo sitio web</li><li>Permitirle ver nuestros diseños iniciales y evaluar el progreso</li><li>Brindarle una oportunidad para dar retroalimentación</li></ol><p>Si no está satisfecho, no hay obligación de continuar. Le reembolsaremos su pago siempre y cuando solo se haya completado el diseño de la página de inicio.</p>'
    }
]

const FaqsEs = ({ calcLink }) => {
    return (

        <div style={{ backgroundImage: `url(${bg})` }} className="faqs static">
            <div id="faqs" style={{ position: 'relative', top: '-100px' }}></div>
            <div className="container">


                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <div>
                                <h2 className="title-gradient">¿Alguna pregunta que le <span className="color-yellow">gustaría resolver?</span></h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="faqs__container grid">
                            <div className="faqs__content">
                                <div className="accordion" id="accordionFaqs">
                                    {faqs.map((faq, index) => (
                                        <div className="accordion-item faq-item box" key={index}>
                                            <h2 className="accordion-header" id={`header-${index}`}>
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#content-${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`content-${index}`}
                                                >
                                                    {faq.title}
                                                </button>
                                            </h2>
                                            <div
                                                id={`content-${index}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`header-${index}`}
                                                data-bs-parent="#accordionFaqs"
                                            >
                                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.content }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="faqs__call d-flex flex-column">
                                <img src="/images/1665701539301.jpeg" alt="Erick" width="" />

                                <h3 className='title-gradient'>Hable con Erick, nuestro fundador,</h3>
                                <p>Estamos listos para ayudar con cualquier pregunta adicional que pueda tener, también ofrecemos una llamada de consultoría GRATUITA si sólo necesita orientación para su nuevo proyecto.</p>
                                <a
                                    className="button button--bordered"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={calcLink}
                                >
                                    <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                                    Hablar con Erick
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default FaqsEs;
